import * as React from 'react';
import type { HeadFC } from 'gatsby';
import '../../global-styles/index.scss';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import PhotoBlock, { PhotoBlockModel } from '../../components/photo-block/photo-block';
import MethodsList from '../../components/methods';
import { Helmet } from 'react-helmet';
import background from '../../assets/background-1.webp';
import sim from '../../assets/personal-photo-2.webp';
import apie_terapija from '../../assets/apie-terapija.webp';
import issilavinimas from '../../assets/issilavinimas.webp';

const blockConfig: PhotoBlockModel[] = [
	{
		imgUrl: apie_terapija,
		imgAlt: '',
		isInverted: false,
		backgroundColor: 'white'
	},
	{
		imgUrl: sim,
		imgAlt: 'Simona Grigaraitė',
		isInverted: false,
		backgroundColor: 'white',
		loading: 'eager'
	},
	{
		imgUrl: issilavinimas,
		imgAlt: '',
		isInverted: true
	}
];

const AboutPage = () => {
	setTimeout(() => {
		document.querySelector('main')!.className = 'about-page';
	}, 100);
	return (
		<main className="about-page is-hidden">
			<Helmet
				htmlAttributes={{
					lang: 'lt'
				}}>
				<title>Aš ir psichoterapija | Simona Grigaraitė</title>
				<meta name="description" content="Esu gydytoja psichiatrė, individualios psichodinaminės psichoterapijos kandidatė." />
				<meta name="image" content={background} />
				<meta name="og:description" content="Esu gydytoja psichiatrė, individualios psichodinaminės psichoterapijos kandidatė." />
				<meta name="og:image" content={background} />
			</Helmet>
			<Header></Header>
			<div className="main-wrapper is-vertical">
				<h1>Aš ir psichoterapija</h1>
			</div>
			<PhotoBlock {...blockConfig[1]}>
				<h2>Apie mane</h2>
				<p className="blog-paragraph">
					Esu gydytoja psichiatrė, individualios psichodinaminės psichoterapijos kandidatė. Kaip gydytoja dirbu Antakalnio psichikos
					sveikatos centre. Teikiu pagalbą žmonėmis, kuriuos vargina įvairūs psichikos sutrikimai, dažniausiai, kai reikalingas
					medikamentinis gydymas būsenai palengvinti.
				</p>
				<p className="blog-paragraph">
					Psichoterapija mane visuomet žavėjo, nes kuria sąlygas rasti atsakymus į savo būties klausimus per ryšį ir pokalbį su kitu. Mano
					giliu įsitikinimu, būti pamatytam, išgirstam, atrastam reiškia kelią į vidinį gijimą.
				</p>
			</PhotoBlock>
			<PhotoBlock {...blockConfig[2]}>
				<h2 id="education">Išsilavinimas:</h2>
				<ul aria-labelledby="#education" className="blog-paragraph">
					<li>
						<p>2013-2019 m. – Lietuvos sveikatos mokslų universitetas, medicinos gydytojo kvalifikacija;</p>
					</li>
					<li>
						<p>
							2019-2023 m. – Vilniaus universitetas, suaugusiųjų psichiatrijos rezidentūra (Respublikinė Vilniaus psichiatrijos ligoninė,
							Vilniaus miesto psichikos sveikatos centras, LSMU NI Palangos klinika streso ligų skyrius, Antakalnio poliklinika ir kt.);
						</p>
					</li>
					<li>
						<p>Nuo 2020 m. – Vilniaus universitetas, individualios psichodinaminės psichoterapijos podiplominių studijų programa;</p>
					</li>
				</ul>
				<p className="blog-paragraph">
					Tobulinu žinias įvairiose mokslinėse-praktinėse konferencijose psichiatrijos, psichoterapijos srityse, esu publikavusi savo
					mokslinius tyrimus WPA kongrese, Biologinės psichiatrijos žurnale, dalyvauju įvairiuose savižudybių prevencijos mokymuose (CAMS,
					SafeTalk).
				</p>
			</PhotoBlock>
			<PhotoBlock {...blockConfig[0]}>
				<h2>Apie psichodinaminę psichoterapiją</h2>
				<p className="blog-paragraph">Psichoterapija – tai žmogaus keitimosi procesas betarpiško ryšio su specialistu metu.</p>
				<p className="blog-paragraph">
					Psichodinaminė samprata remiasi tuo, kad žmogaus motyvaciją, elgesį lemia vidinis konfliktas tarp skirtingų norų, jausmų, o
					simptomas yra tarsi šio konflikto kompromisas. Žmogaus gyvenime įvyksta begalė įvykių, klostosi įvairūs santykiai, kurie
					suformuoja pasaulio matymą, tam tikrus elgesio modelius. Dažniausiai ir sunkumams įtaką daro praeityje buvę skausmingi,
					neįsisąmoninti išgyvenimai, jausmai. Vėliau gyvenime, deja, šie sunkumai yra linkę tam tikru būdu atsikartoti. Užmezgus terapinį
					ryšį, bendradarbiaujant, nešališkas psichoterapeutas padeda šiuos neįsisąmonintus aspektus pačiam pamatyti. Taigi, psichodinaminės
					psichoterapijos uždavinys - suprasti kaip praeities įvykiai lemia gyvenimą dabartyje.
				</p>
				<p className="blog-paragraph">
					Tik išjautus tai, kas neišjausta, išgyvenus, tai, kas neišgyventa, žmogus tampa tikruoju savimi: yra laisvas, lankstus,
					kūrybiškas.
				</p>
			</PhotoBlock>
			<MethodsList backColor={'transparent'}></MethodsList>
			<Footer></Footer>
		</main>
	);
};

export default AboutPage;

export const Head: HeadFC = () => (
	<>
		<title>Aš ir psichoterapija | Simona Grigaraitė</title>
		<meta name="description" content="Esu gydytoja psichiatrė, individualios psichodinaminės psichoterapijos kandidatė." />
		<meta name="image" content={background} />
		<meta name="og:description" content="Esu gydytoja psichiatrė, individualios psichodinaminės psichoterapijos kandidatė." />
		<meta name="og:image" content={background} />
	</>
);
